export default {
  menus: {
    'implement-setting': 'Implement Setting',
    login: 'Login',
    'e-payslip': 'E-PaySlip',
    'e-certificate': 'E-Certificate',
    'e-leave': 'E-Leave',
    'e-time': 'E-Time',
    import: 'Import',
    portal: 'Home',
    workflow: 'Workflow',
    'workflow-setting': 'Workflow Setting',
    'audit-log': 'Audit Log',
    'hr-admin': 'HR Admin',
    'pay-period': 'Pay Period',
    perm: 'Permission',
    topside: {
      dashboard: 'Dashboard',
      organization: 'Organization',
      benefit: 'Benefits and Salary',
      employee: 'Employee',
      report: 'Report',
      setting: 'Setting',
      news: 'News',
      'self-service': 'Employee Self Service'
    }
  },
  'hr-admin': {
    title: 'E-Payslip',
    home: 'Home',
    form: {
      position: 'Position',
      level: 'Level',
      company: 'Company',
      'oc-name': 'Oc Name',
      division: 'Division',
      department: 'Department',
      status: 'Status',
      'employee-code': 'Employee Code',
      'name-surname': 'Name-Surname',
      'oc-name-placeholder': 'Oc Name..',
      'position-placeholder': 'Position..',
      'level-placeholder': 'Level..',
      'division-placeholder': 'Division..',
      'department-placeholder': 'Department..',
      'status-placeholder': 'Status..',
      'employee-code-placeholder': 'Employee Code..',
      'name-surname-placeholder': 'Name-Surname..'
    },
    table: {
      fullname: 'Full Name',
      'employee-code': 'Employee Code',
      division: 'Division',
      department: 'Department',
      action: 'Action'
    }
  },
  'super-admin': {}
}
