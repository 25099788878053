import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getInstance } from '@core/utils'
import { useParams } from 'react-router'

const useFetch = ({
  url = '',
  method = 'GET',
  isPublic = false,
  isMultipart,
  headers,
  baseURL = null,
  localUrl = '',
  useLocal = false
}) => {
  const [pending, setPending] = useState(false)
  const [data, setData] = useState()
  const isMounted = useRef(true) // ใช้ useRef เพื่อติดตามสถานะของ component

  const param = useParams()

  const axiosInstance = React.useMemo(() => {
    return getInstance({ isPublic, redirectWith: param?.companyGroup })
  }, [isPublic, param?.companyGroup])

  // cleanup function ที่จะตั้งค่า isMounted เป็น false เมื่อ component unmount
  useEffect(() => {
    return () => {
      isMounted.current = false // component unmount
    }
  }, [])

  const execute = useCallback(
    async (
      { url: postUrl, payload, headers: executeHeader },
      { onSuccess, onFailed, onFinally } = {}
    ) => {
      if (isMounted.current) {
        setPending(true)
      }
      try {
        const parameter = {
          method,
          headers: {
            ...headers,
            ...executeHeader
          },
          ...(['GET', 'DELETE'].includes(method.toUpperCase())
            ? {
                params: {
                  ...payload,
                  ...(isPublic && { kc: param?.companyGroup })
                }
              }
            : {
                data: {
                  ...payload,
                  ...(isPublic && { kc: param?.companyGroup })
                }
              })
        }

        if (!isMultipart) {
          parameter.headers['Content-Type'] = 'application/json'
        } else {
          const rewriteForm = new FormData()
          rewriteForm.append('file', payload.get('file'))
          parameter.data = rewriteForm
        }

        const opt = {
          url: postUrl ? url + postUrl : url,
          ...parameter
        }

        if (baseURL) {
          Object.assign(opt, {
            baseURL
          })
        }

        if (useLocal) {
          axiosInstance.defaults.baseURL = ''
          opt.url = localUrl
        }
        const response = await axiosInstance(opt)

        if (response) {
          if (isMounted.current) {
            // ตรวจสอบว่า component ยัง mount อยู่หรือไม่
            setData(onSuccess ? await onSuccess(response) : response)
          }
        } else {
          throw new Error('No Response')
        }
      } catch (error) {
        console.error(error)

        if (onFailed) {
          await onFailed(error)
        }
      } finally {
        if (onFinally) {
          await onFinally()
        }
        if (isMounted.current) {
          // ตรวจสอบว่า component ยัง mount อยู่หรือไม่
          setPending(false)
        }
      }
    },
    [
      axiosInstance,
      headers,
      isMultipart,
      method,
      url,
      baseURL,
      isPublic,
      localUrl,
      useLocal,
      param?.companyGroup
    ]
  )

  return { execute, pending, data }
}

export default useFetch
